<template>
  <div class="page-login-desktop">
    <v-card flat>
      <v-card-title>
        <PageTitle class="w-100" title="ویرایش مشترکین" icon="mdi-pencil">
          <v-btn color="primary" text elevation="0" @click="$router.back()">
            بازگشت
            <v-icon class="mr-2">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </PageTitle>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field label="مدت زمان (روز)" type="number" hide-details outlined v-model="subscription.Duration"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select label="انتخاب اشتراک" hide-details outlined v-model="subscription.Subscriptionname"
                      :items="prices"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-combobox multiple deletable-chips small-chips label="انتخاب محله" hide-details outlined
                        v-model="subscription.Namesofneighborhoods" :items="locations" item-value="id"
                        item-text="name"/>
          </v-col>
          <v-col cols="12" lg="6">
            <v-textarea
                outlined
                hide-details
                placeholder="توضیحات"
                v-model="subscription.description"
            />
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            elevation="0"
            color="primary" :loading="subscription.Loading" @click="update" large>
          تایید
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {fetchItems, update} from "@Newfiling/module-subscription/src/api";
import PageTitle from "@/components/PageTitle"
import {AppConfig} from "@Newfiling/Services"

export default {
  name: 'DesktopLogin',

  components: {
    PageTitle
  },

  data() {
    return {
      subscription: {
        Loading: false,
        Duration: null,
        Namesofneighborhoods: null,
        Subscriptionname: null,
        Codeajans: null,
      },
      prices: [],
      locations: [],

    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست مشترکین',
        disabled: false,
        href: '/panel/subscriptions/list',
      },
      {
        text: 'ویرایش مشترکین',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig) || {};
      this.prices = appConfig.subscribstring.map(item => item.Subscriptionname)
      this.locations = appConfig.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
      await this.fetchItems();
    });

  },

  methods: {
    async fetchItems() {

      try {
        const res = (await fetchItems({
          modal: false,
          Nameandfamily: "",
          Agencyname: "",
          Active: 1,
          remainingday: 0,
          codeajans: this.$route.params.id,
          take: 1,
          skip: 0,
          sort: null,
        }))?.data?.list[0] || {};

        this.subscription = {
          ...this.subscription,
          Loading: false,
          Duration: res.remainingday,
          description: res.description,
          Subscriptionname: res.subscriptionname,
          Namesofneighborhoods: this.locations.filter(i => res.namesofneighborhoods
              ?.split('-')
              ?.map(j => parseInt(j))
              ?.includes(i.id)
          ),
          Codeajans: this.$route.params.id,
        }
      } catch (e) {
        console.log(e)
      }

    },

    async update() {
      this.subscription.Loading = true;
      try {
        await update({
          ...this.subscription,
          Namesofneighborhoods: this.subscription.Namesofneighborhoods.map(i => i.id).join('-')
        });
        this.$toast.success('مشترک با موفقیت ویرایش شد.');
        await this.$router.back()
      } catch (e) {
        console.log(e)
      }
      this.subscription.Loading = false;
    },
  },


}
</script>
